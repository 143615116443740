import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Loading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mt-4 bg-gray-900 overflow-y-auto flex-auto min-h-0" };
const _hoisted_2 = { class: "w-full flex-grow flex justify-center items-center h-full" };
const __default__ = {
  inheritAttrs: false
};
export default /* @__PURE__ */ _defineComponent({
  ...__default__,
  __name: "View",
  props: {
    view: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isProduction = process.env.NODE_ENV === "production";
    const isWebpack = process.env.BUILDER_TYPE === "webpack" || isProduction;
    const activityComponent = defineAsyncComponent(() => {
      return new Promise((resolve, _reject) => {
        if (!isWebpack) {
          const comps = import.meta.glob(
            /* @vite-ignore */
            "./**/*.vue"
          );
          return comps[
            /* @vite-ignore */
            `./Views/${props.view}.vue`
          ]().then(
            (component) => resolve(component.default)
          );
        }
        import(
          /* @vite-ignore */
          `./Views/${props.view}.vue`
        ).then(
          (component) => resolve(component)
        );
      });
    });
    return (_ctx, _cache) => {
      const _component_AppLoading = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_Suspense, null, {
          fallback: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppLoading, { class: "relative" })
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(activityComponent)), _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16))
          ]),
          _: 1
        }))
      ]);
    };
  }
});

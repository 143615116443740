"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = { class: "text-2xs tracking-1.5 flex items-center justify-center text-center" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "mx-3 flex items-center gap-0.5 text-sm font-semibold" };
const _hoisted_4 = ["onClick"];
const _hoisted_5 = { class: "flex items-center gap-2" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    disabled: Boolean,
    page: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    rowClass: {
      type: String,
      default: ""
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      const _component_BaseSelectorItem = _resolveComponent("BaseSelectorItem");
      const _component_AppPaginationRowSelector = _resolveComponent("AppPaginationRowSelector");
      const _component_BasePaginationWrapper = _resolveComponent("BasePaginationWrapper");
      return _openBlock(), _createBlock(_component_BasePaginationWrapper, _mergeProps(_ctx.$attrs, {
        class: "flex flex-wrap items-center justify-between text-sm w-full",
        disabled: __props.disabled,
        page: __props.page,
        limit: __props.limit,
        "total-count": __props.totalCount
      }), {
        summary: _withCtx(({ from, to }) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("pagination.paginationPages", { from, to, totalCount: __props.totalCount })), 1)
        ]),
        default: _withCtx(({
          hasPrevPage,
          hasNextPage,
          handleClickEvent,
          handleNextEvent,
          handlePrevEvent,
          pagesToDisplay
        }) => [
          _createElementVNode("div", _hoisted_1, [
            hasPrevPage ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(
                hasPrevPage ? "cursor-pointer text-blue-500" : "text-gray-600"
              ),
              onClick: handlePrevEvent
            }, [
              _createVNode(_component_BaseIcon, {
                name: "caret-thin",
                class: "h-auto w-3"
              })
            ], 10, _hoisted_2)) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pagesToDisplay, (displayPage, index) => {
                return _openBlock(), _createBlock(_component_BaseSelectorItem, {
                  key: `pagination-page-${displayPage}-${index}`,
                  "model-value": __props.page,
                  value: displayPage,
                  class: "cursor-pointer",
                  "onUpdate:modelValue": handleClickEvent
                }, {
                  default: _withCtx(({ active }) => [
                    _createElementVNode("span", {
                      class: _normalizeClass(["px-2 py-1 hover:bg-blue-500 hover:bg-opacity-80 hover:text-blue-800", {
                        "bg-blue-500 text-blue-800": active
                      }])
                    }, _toDisplayString(displayPage), 3)
                  ]),
                  _: 2
                }, 1032, ["model-value", "value", "onUpdate:modelValue"]);
              }), 128))
            ]),
            hasNextPage ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(
                hasNextPage ? "cursor-pointer text-blue-500" : "text-gray-600"
              ),
              onClick: handleNextEvent
            }, [
              _createVNode(_component_BaseIcon, {
                name: "caret-thin",
                class: "h-auto w-3 -rotate-180"
              })
            ], 10, _hoisted_4)) : _createCommentVNode("", true)
          ])
        ]),
        "row-select": _withCtx(({ rowOptions, handleUpdateLimit }) => [
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "rows-prefix"),
            _createVNode(_component_AppPaginationRowSelector, {
              disabled: __props.disabled,
              limit: __props.limit,
              options: rowOptions,
              "selected-class": __props.rowClass,
              "onUpdate:modelValue": handleUpdateLimit
            }, null, 8, ["disabled", "limit", "options", "selected-class", "onUpdate:modelValue"])
          ])
        ]),
        _: 3
      }, 16, ["disabled", "page", "limit", "total-count"]);
    };
  }
});

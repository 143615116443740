import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Account/Positions/FilterOption.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Field/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx } from "vue";
import { ActivityTab } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketFilter",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    tab: {
      type: String,
      required: true
    },
    tokens: {
      type: Array,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const options = computed(
      () => props.tokens.map((token) => ({
        token,
        display: token.symbol,
        value: token.denom
      }))
    );
    const value = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      }
    });
    return (_ctx, _cache) => {
      const _component_PartialsAccountPositionsFilterOption = __nuxt_component_0;
      const _component_AppSelectField = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppSelectField, {
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(value) ? value.value = $event : null),
        options: _unref(options),
        placeholder: __props.tab === _unref(ActivityTab).WalletHistory ? _ctx.$t("walletHistory.transfers.asset") : _ctx.$t("account.positions.market.label"),
        searchable: "",
        clearable: "",
        "data-cy": "universal-table-filter-by-asset-input"
      }, {
        "selected-option": _withCtx(({ option }) => [
          option ? (_openBlock(), _createBlock(_component_PartialsAccountPositionsFilterOption, {
            key: 0,
            option
          }, null, 8, ["option"])) : _createCommentVNode("", true)
        ]),
        option: _withCtx(({ option, active }) => [
          _createVNode(_component_PartialsAccountPositionsFilterOption, {
            option,
            active
          }, null, 8, ["option", "active"])
        ]),
        _: 1
      }, 8, ["modelValue", "options", "placeholder"]);
    };
  }
});

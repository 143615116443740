import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Activity/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "container h-full max-h-screen-excluding-header min-h-lg" };
const _hoisted_2 = { class: "w-full mx-auto 3xl:w-11/12 4xl:w-10/12 relative h-full-excluding-header" };
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "activity",
  setup(__props) {
    false && {};
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    onMounted(() => {
      Promise.all([spotStore.init(), derivativeStore.init()]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_PartialsActivity = __nuxt_component_0;
      const _component_AppHocLoading = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: _unref(status),
        class: "h-full"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_PartialsActivity)
            ])
          ])
        ]),
        _: 1
      }, 8, ["status"]);
    };
  }
});

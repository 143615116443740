import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/NumericInput.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between" };
const _hoisted_2 = {
  key: 0,
  class: "leading-none"
};
const _hoisted_3 = { class: "flex items-center justify-between no-shadow" };
const _hoisted_4 = {
  key: 0,
  class: "ml-3"
};
const _hoisted_5 = {
  key: 1,
  class: "mr-3"
};
const _hoisted_6 = {
  key: 2,
  class: "mr-3 text-gray-300"
};
const __default__ = {
  inheritAttrs: false
};
export default /* @__PURE__ */ _defineComponent({
  ...__default__,
  __name: "Numeric",
  props: {
    sm: Boolean,
    valid: Boolean,
    noPadding: Boolean,
    transparentBg: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    inputClasses: {
      type: String,
      default: ""
    },
    wrapperClasses: {
      type: String,
      default: ""
    }
  },
  emits: ["blur"],
  setup(__props, { emit }) {
    const props = __props;
    const slots = useSlots();
    const wrapperClass = computed(() => {
      const result = ["shadow-none"];
      if (!props.transparentBg) {
        result.push("input-wrapper p-0");
      }
      result.push(props.wrapperClasses);
      return result.join(" ");
    });
    const inputClass = computed(() => {
      const result = [];
      if (!props.noPadding) {
        result.push("px-3");
      }
      if (props.sm) {
        result.push("h-8");
      }
      if (props.transparentBg) {
        result.push("input-bg-transparent");
      }
      result.push(props.inputClasses);
      return result.join(" ");
    });
    const classes = computed(() => {
      const result = ["w-full"];
      if (props.valid) {
        result.push("is-valid");
      }
      if (!props.valid && props.errors.length > 0) {
        result.push("is-invalid");
      }
      return result.join(" ");
    });
    function handleBlur(e) {
      const { value } = e == null ? void 0 : e.target;
      if (isNaN(parseInt(value))) {
        return;
      }
      debounceOnBlur(value);
    }
    const debounceOnBlur = useDebounceFn(
      (value) => emit("blur", value),
      500
    );
    return (_ctx, _cache) => {
      const _component_BaseNumericInput = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full input-wrap", _unref(classes)])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _unref(slots).context ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "context")
            ])) : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_unref(wrapperClass))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _unref(slots).prefix ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "prefix")
              ])) : _createCommentVNode("", true),
              _createVNode(_component_BaseNumericInput, _mergeProps(_ctx.$attrs, {
                class: [_unref(inputClass), "input"],
                onBlur: handleBlur
              }), null, 16, ["class"]),
              _unref(slots).max ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "max")
              ])) : _createCommentVNode("", true),
              _unref(slots).addon ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "addon")
              ])) : _createCommentVNode("", true)
            ])
          ], 2)
        ])
      ], 2);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/SelectorItem.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Dropdown.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["classes"];
const _hoisted_2 = {
  key: 0,
  class: "font-semibold text-white text-sm"
};
const _hoisted_3 = { class: "p-2 bg-gray-850 shadow-sm" };
const _hoisted_4 = { class: "group flex cursor-pointer items-center justify-between rounded hover:bg-blue-500 p-2 gap-2 text-sm" };
export default /* @__PURE__ */ _defineComponent({
  __name: "RowSelector",
  props: {
    limit: {
      type: Number,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedClass: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const selectedOption = computed(
      () => props.options.find((option) => option === props.limit)
    );
    function handleUpdateLimit(limit) {
      emit("update:modelValue", limit);
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_BaseSelectorItem = __nuxt_component_1;
      const _component_BaseDropdown = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_BaseDropdown, {
        class: "inline-block",
        placement: "top-end"
      }, {
        default: _withCtx(({ shown }) => [
          _createElementVNode("div", {
            class: "flex items-center gap-2 rounded",
            classes: __props.selectedClass || "border border-gray-400 py-2 px-3"
          }, [
            _unref(selectedOption) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(selectedOption)), 1)) : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_BaseIcon, {
                name: "chevron-down",
                class: _normalizeClass(["h-4 w-4 min-w-4 rotate-180", {
                  "ease-in-out duration-300 rotate-0": shown
                }])
              }, null, 8, ["class"])
            ])
          ], 8, _hoisted_1)
        ]),
        content: _withCtx(({ close }) => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (item) => {
              return _openBlock(), _createBlock(_component_BaseSelectorItem, {
                key: `selector-row-item-${item}`,
                value: item.toString(),
                "model-value": __props.limit,
                "onUpdate:modelValue": handleUpdateLimit,
                onClick: close
              }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["font-semibold group-hover:text-black text-sm", {
                        "text-white": !active,
                        "text-blue-500": active
                      }])
                    }, _toDisplayString(item), 3)
                  ])
                ]),
                _: 2
              }, 1032, ["value", "model-value", "onClick"]);
            }), 128))
          ])
        ]),
        _: 1
      });
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Activity/Common/Toolbar.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Activity/View.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { Status, StatusType } from "@injectivelabs/utils";
import {
  ActivityTab,
  ActivityView,
  ActivityField
} from "@/types";
import {
  executionOrderTypeToOrderTypes,
  executionOrderTypeToOrderExecutionTypes,
  executionOrderTypeToTradeExecutionTypes
} from "@/app/client/utils/activity";
import { getDenomsFromToken } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "ViewWrapper",
  props: {
    tab: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    },
    status: {
      type: Object,
      default: () => new Status(StatusType.Idle)
    }
  },
  setup(__props, { expose }) {
    const props = __props;
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const formValues = useFormValues();
    const markets = computed(
      () => [ActivityTab.Spot, ActivityTab.WalletHistory].includes(props.tab) ? spotStore.markets : derivativeStore.markets
    );
    const hasActiveFilters = computed(
      () => !!formValues.value[ActivityField.Denom] || !!formValues.value[ActivityField.Side] || !!formValues.value[ActivityField.Type]
    );
    const tokens = computed(() => {
      if (!markets.value) {
        return [];
      }
      const tokens2 = markets.value.reduce((tokens3, market) => {
        return [...tokens3, market.baseToken, market.quoteToken];
      }, []);
      const uniqueTokens = [
        ...new Map(tokens2.map((token) => [token.denom, token])).values()
      ];
      return uniqueTokens;
    });
    const selectedToken = computed(() => {
      if (!formValues.value[ActivityField.Denom]) {
        return void 0;
      }
      return tokens.value.find(
        (token) => token.denom === formValues.value[ActivityField.Denom]
      );
    });
    const selectedDenoms = computed(() => {
      if (!selectedToken.value) {
        return [];
      }
      return getDenomsFromToken(selectedToken.value);
    });
    const selectedSymbol = computed(() => {
      if (!selectedToken.value) {
        return "";
      }
      return selectedToken.value.symbol;
    });
    const marketIds = computed(() => {
      if (!selectedDenoms.value.length) {
        return void 0;
      }
      return markets.value.filter(
        ({ baseToken, quoteToken }) => selectedDenoms.value.some(
          (denom) => [baseToken.denom, quoteToken.denom].includes(denom)
        )
      ).map(({ marketId }) => marketId);
    });
    const filterParams = computed(() => {
      const defaultFilterParams = {
        marketIds: marketIds.value
      };
      if (!hasActiveFilters.value) {
        return void 0;
      }
      switch (props.view) {
        case ActivityView.FundingPayments:
          return defaultFilterParams;
        case ActivityView.DerivativeOrderHistory:
        case ActivityView.SpotOrderHistory:
          return {
            ...defaultFilterParams,
            orderTypes: executionOrderTypeToOrderTypes(
              formValues.value[ActivityField.Type]
            ),
            executionTypes: executionOrderTypeToOrderExecutionTypes(
              formValues.value[ActivityField.Type]
            ),
            direction: formValues.value[ActivityField.Side]
          };
        case ActivityView.DerivativeTradeHistory:
        case ActivityView.SpotTradeHistory:
          return {
            ...defaultFilterParams,
            orderTypes: executionOrderTypeToOrderTypes(
              formValues.value[ActivityField.Type]
            ),
            executionTypes: executionOrderTypeToTradeExecutionTypes(
              formValues.value[ActivityField.Type]
            ),
            direction: formValues.value[ActivityField.Side]
          };
        case ActivityView.WalletTransfers:
          return {
            denom: formValues.value[ActivityField.Denom]
          };
        default:
          return {};
      }
    });
    expose({
      filterParams
    });
    return (_ctx, _cache) => {
      const _component_PartialsActivityCommonToolbar = __nuxt_component_0;
      const _component_PartialsActivityView = __nuxt_component_1;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_PartialsActivityCommonToolbar, _mergeProps({
          tab: __props.tab,
          view: __props.view,
          status: __props.status
        }, _ctx.$attrs), null, 16, ["tab", "view", "status"]),
        (_openBlock(), _createBlock(_component_PartialsActivityView, _mergeProps({
          view: __props.view,
          status: __props.status,
          symbol: _unref(selectedSymbol),
          denoms: _unref(selectedDenoms),
          type: _unref(formValues)[_unref(ActivityField).Type],
          side: _unref(formValues)[_unref(ActivityField).Side]
        }, {
          key: __props.view,
          class: "h-full"
        }), null, 16))
      ], 64);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Activity/Common/MarketFilter.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Field/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Account/RefreshButton.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col sm:flex-row justify-between gap-4 w-full" };
const _hoisted_2 = { class: "grid grid-cols-4 items-center gap-4 w-full" };
const _hoisted_3 = { class: "items-center flex gap-1" };
const _hoisted_4 = /* @__PURE__ */ _createElementVNode("div", { id: "activity-toolbar-action" }, null, -1);
import { TradeDirection, TradeExecutionType } from "@injectivelabs/sdk-ts";
import { Status, StatusType } from "@injectivelabs/utils";
import {
  ActivityTab,
  ActivityView,
  ActivityField,
  ConditionalOrderType
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Toolbar",
  props: {
    tab: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    },
    status: {
      type: Object,
      default: () => new Status(StatusType.Idle)
    }
  },
  emits: ["update:filter", "reset:filter"],
  setup(__props, { emit }) {
    const props = __props;
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const { t } = useLang();
    const { value: denom } = useStringField({ name: ActivityField.Denom, rule: "" });
    const { value: side } = useStringField({ name: ActivityField.Side, rule: "" });
    const { value: type } = useStringField({ name: ActivityField.Type, rule: "" });
    const hasActiveFilters = computed(
      () => !!denom.value || !!side.value || !!type.value
    );
    const markets = computed(
      () => [ActivityTab.Spot, ActivityTab.WalletHistory].includes(props.tab) ? spotStore.markets : derivativeStore.markets
    );
    const tokens = computed(() => {
      if (!markets.value) {
        return [];
      }
      const tokens2 = markets.value.reduce((tokens3, market) => {
        return [...tokens3, market.baseToken, market.quoteToken];
      }, []);
      const uniqueTokens = [
        ...new Map(tokens2.map((token) => [token.denom, token])).values()
      ];
      return uniqueTokens;
    });
    const sideOptions = computed(() => {
      if (props.view === ActivityView.Positions) {
        return [
          {
            display: t("trade.long"),
            value: TradeDirection.Long
          },
          {
            display: t("trade.short"),
            value: TradeDirection.Short
          }
        ];
      }
      return [
        {
          display: t("trade.buy"),
          value: TradeDirection.Buy
        },
        {
          display: t("trade.sell"),
          value: TradeDirection.Sell
        }
      ];
    });
    const typeOptions = computed(() => {
      let result = [
        {
          display: t("trade.limit"),
          value: `${TradeExecutionType.LimitFill}`
        },
        {
          display: t("trade.market"),
          value: `${TradeExecutionType.Market}`
        }
      ];
      if (props.tab === ActivityTab.Spot) {
        return result;
      }
      const derivativeTypes = [
        {
          display: `${t("trade.stopLoss")} ${t("trade.limit")}`,
          value: `${TradeExecutionType.LimitFill}-${ConditionalOrderType.StopLoss}`
        },
        {
          display: `${t("trade.stopLoss")} ${t("trade.market")}`,
          value: `${TradeExecutionType.Market}-${ConditionalOrderType.StopLoss}`
        },
        {
          display: `${t("trade.takeProfit")} ${t("trade.limit")}`,
          value: `${TradeExecutionType.LimitFill}-${ConditionalOrderType.TakeProfit}`
        },
        {
          display: `${t("trade.takeProfit")} ${t("trade.market")}`,
          value: `${TradeExecutionType.Market}-${ConditionalOrderType.TakeProfit}`
        }
      ];
      if (props.view === ActivityView.DerivativeTriggers) {
        return derivativeTypes;
      }
      if ([
        ActivityView.DerivativeOrderHistory,
        ActivityView.DerivativeTradeHistory
      ].includes(props.view)) {
        result = [...result, ...derivativeTypes];
      }
      return result;
    });
    function handleClearFilters() {
      emit("reset:filter");
    }
    function handleUpdate() {
      emit("update:filter");
    }
    return (_ctx, _cache) => {
      const _component_PartialsActivityCommonMarketFilter = __nuxt_component_0;
      const _component_AppSelectField = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      const _component_AppButton = __nuxt_component_3;
      const _component_PartialsAccountRefreshButton = __nuxt_component_4;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PartialsActivityCommonMarketFilter, {
            modelValue: _unref(denom),
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event) => _isRef(denom) ? denom.value = $event : null),
              handleUpdate
            ],
            class: "col-span-2 sm:col-span-1",
            tab: __props.tab,
            tokens: _unref(tokens)
          }, null, 8, ["modelValue", "tab", "tokens"]),
          [_unref(ActivityTab).Spot, _unref(ActivityTab).Derivatives].includes(__props.tab) && ![_unref(ActivityView).SpotOrders, _unref(ActivityView).DerivativeOrders].includes(
            __props.view
          ) ? (_openBlock(), _createBlock(_component_AppSelectField, {
            key: 0,
            modelValue: _unref(type),
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event) => _isRef(type) ? type.value = $event : null),
              handleUpdate
            ],
            class: "col-span-2 sm:col-span-1",
            options: _unref(typeOptions),
            placeholder: _ctx.$t("activity.common.type"),
            clearable: "",
            "data-cy": "universal-table-filter-by-type-drop-down"
          }, null, 8, ["modelValue", "options", "placeholder"])) : _createCommentVNode("", true),
          __props.tab !== _unref(ActivityTab).WalletHistory && __props.view !== _unref(ActivityView).FundingPayments ? (_openBlock(), _createBlock(_component_AppSelectField, {
            key: 1,
            modelValue: _unref(side),
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event) => _isRef(side) ? side.value = $event : null),
              handleUpdate
            ],
            class: "col-span-2 sm:col-span-1",
            options: _unref(sideOptions),
            placeholder: _ctx.$t("trade.side"),
            clearable: "",
            "data-cy": "universal-table-filter-by-asset-input"
          }, null, 8, ["modelValue", "options", "placeholder"])) : _createCommentVNode("", true),
          !__props.status.isLoading() ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["flex items-center justify-between gap-1 text-sm col-span-4 sm:col-span-1", { "justify-self-end": !_unref(hasActiveFilters) }])
          }, [
            _unref(hasActiveFilters) ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              class: "border-gray-500 text-gray-500 px-3",
              sm: "",
              onClick: handleClearFilters
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_BaseIcon, {
                    name: "close",
                    md: ""
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("filters.clearAll")), 1)
                ])
              ]),
              _: 1
            })) : _createCommentVNode("", true),
            _createVNode(_component_PartialsAccountRefreshButton, {
              status: __props.status,
              view: __props.view,
              onClick: handleClearFilters
            }, null, 8, ["status", "view"])
          ], 2)) : _createCommentVNode("", true)
        ]),
        _hoisted_4
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/Card/Select.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Select/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "overflow-x-auto hide-scrollbar flex-none" };
const _hoisted_2 = { class: "flex lg:grid grid-cols-4 gap-4" };
const _hoisted_3 = { class: "font-semibold text-sm md:text-lg" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { key: 2 };
const _hoisted_7 = { key: 3 };
const _hoisted_8 = { class: "text-sm whitespace-nowrap" };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = { key: 2 };
const _hoisted_12 = { key: 3 };
import { Status, StatusType } from "@injectivelabs/utils";
import { ActivityTab } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Navigation",
  props: {
    tab: {
      type: String,
      required: true
    },
    status: {
      type: Object,
      default: () => new Status(StatusType.Idle)
    }
  },
  emits: ["update:tab"],
  setup(__props, { emit }) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const positionStore = usePositionStore();
    const spotStore = useSpotStore();
    const activeTab = computed({
      get: () => props.tab,
      set: (tab) => {
        emit("update:tab", tab);
      }
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_CommonCardSelect = __nuxt_component_1;
      const _component_AppSelectButton = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_unref(ActivityTab)), (tabOption) => {
            return _openBlock(), _createBlock(_component_AppSelectButton, {
              key: `activity-tab-${tabOption}`,
              modelValue: _unref(activeTab),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeTab) ? activeTab.value = $event : null),
              value: tabOption
            }, {
              default: _withCtx(({ active }) => [
                _createVNode(_component_CommonCardSelect, {
                  "data-cy": "activity-open-positions-panel",
                  active,
                  "show-loading": active && __props.status.isLoading()
                }, {
                  icon: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, [
                      tabOption === _unref(ActivityTab).Positions ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(positionStore).subaccountPositionsCount), 1)) : tabOption === _unref(ActivityTab).Derivatives ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(derivativeStore).subaccountOrdersCount), 1)) : tabOption === _unref(ActivityTab).Spot ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(spotStore).subaccountOrdersCount), 1)) : tabOption === _unref(ActivityTab).WalletHistory ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                        _createVNode(_component_BaseIcon, {
                          name: "wallet",
                          class: "w-3 md:w-3.5 h-auto"
                        })
                      ])) : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_8, [
                      tabOption === _unref(ActivityTab).Positions ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("activity.positions")), 1)) : tabOption === _unref(ActivityTab).Derivatives ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("activity.derivativeOrders")), 1)) : tabOption === _unref(ActivityTab).Spot ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("activity.spotOrders")), 1)) : tabOption === _unref(ActivityTab).WalletHistory ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("activity.walletHistory")), 1)) : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["active", "show-loading"])
              ]),
              _: 2
            }, 1032, ["modelValue", "value"]);
          }), 128))
        ])
      ]);
    };
  }
});

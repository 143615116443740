import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { BigNumberInBase } from "@injectivelabs/utils";
import { ActivityView } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "RefreshButton",
  props: {
    view: {
      type: String,
      required: true
    }
  },
  emits: ["click"],
  setup(__props, { emit }) {
    const props = __props;
    const activityStore = useActivityStore();
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const showRefreshBtn = computed(() => {
      var _a, _b, _c, _d;
      if (props.view === ActivityView.DerivativeOrderHistory) {
        const latestVisibleOrders = derivativeStore.subaccountOrderHistory;
        if (latestVisibleOrders.length === 0) {
          return;
        }
        return new BigNumberInBase(
          ((_a = activityStore.latestDerivativeOrderHistory) == null ? void 0 : _a.updatedAt) || "0"
        ).gt(latestVisibleOrders[0].updatedAt);
      }
      if (props.view === ActivityView.DerivativeTradeHistory) {
        const latestVisibleTrades = derivativeStore.subaccountTrades;
        if (latestVisibleTrades.length === 0) {
          return;
        }
        return new BigNumberInBase(
          ((_b = activityStore.latestDerivativeTrade) == null ? void 0 : _b.executedAt) || "0"
        ).gt(latestVisibleTrades[0].executedAt);
      }
      if (props.view === ActivityView.SpotOrderHistory) {
        const latestVisibleOrders = spotStore.subaccountOrderHistory;
        if (latestVisibleOrders.length === 0) {
          return;
        }
        return new BigNumberInBase(
          ((_c = activityStore.latestSpotOrderHistory) == null ? void 0 : _c.updatedAt) || "0"
        ).gt(latestVisibleOrders[0].updatedAt);
      }
      if (props.view === ActivityView.SpotTradeHistory) {
        const latestVisibleTrades = spotStore.subaccountTrades;
        if (latestVisibleTrades.length === 0) {
          return;
        }
        return new BigNumberInBase(
          ((_d = activityStore.latestSpotTrade) == null ? void 0 : _d.executedAt) || "0"
        ).gt(latestVisibleTrades[0].executedAt);
      }
    });
    function handleRefresh() {
      emit("click");
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      return _unref(showRefreshBtn) ? (_openBlock(), _createBlock(_component_AppButton, {
        key: 0,
        name: "exchange",
        class: "border-blue-500 text-blue-500 px-3",
        sm: "",
        onClick: handleRefresh
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString([
            _unref(ActivityView).DerivativeTradeHistory,
            _unref(ActivityView).SpotTradeHistory
          ].includes(__props.view) ? _ctx.$t("activity.fetchTrades") : _ctx.$t("activity.fetchOrders")), 1)
        ]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/Separator.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center gap-4 overflow-x-auto hide-scrollbar flex-none" };
const _hoisted_2 = { class: "flex items-center gap-1" };
const _hoisted_3 = { class: "whitespace-nowrap" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { key: 2 };
const _hoisted_7 = { key: 3 };
const _hoisted_8 = { key: 4 };
const _hoisted_9 = { key: 5 };
const _hoisted_10 = { key: 6 };
const _hoisted_11 = { key: 7 };
const _hoisted_12 = { key: 8 };
const _hoisted_13 = {
  key: 0,
  id: "activity-position-tab-count"
};
const _hoisted_14 = { id: "activity-position-tab-count-default" };
const _hoisted_15 = {
  key: 1,
  id: "activity-spot-tab-count"
};
const _hoisted_16 = { id: "activity-spot-tab-count-default" };
const _hoisted_17 = {
  key: 2,
  id: "activity-derivative-tab-count"
};
const _hoisted_18 = { id: "activity-derivative-tab-count-default" };
const _hoisted_19 = {
  key: 3,
  id: "activity-derivative-triggers-tab-count"
};
const _hoisted_20 = { id: "activity-derivative-triggers-tab-count-default" };
import { ActivityTab, ActivityView } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Tabs",
  props: {
    tab: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  emits: ["update:view"],
  setup(__props, { emit }) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const positionStore = usePositionStore();
    const spotStore = useSpotStore();
    const view = computed({
      get: () => props.view,
      set: (value) => {
        emit("update:view", value);
      }
    });
    const tabViewList = computed(() => {
      if (props.tab === ActivityTab.Positions) {
        return [ActivityView.Positions, ActivityView.FundingPayments];
      }
      if (props.tab === ActivityTab.Derivatives) {
        return [
          ActivityView.DerivativeOrders,
          ActivityView.DerivativeTriggers,
          ActivityView.DerivativeOrderHistory,
          ActivityView.DerivativeTradeHistory
        ];
      }
      if (props.tab === ActivityTab.Spot) {
        return [
          ActivityView.SpotOrders,
          ActivityView.SpotOrderHistory,
          ActivityView.SpotTradeHistory
        ];
      }
      return [
        ActivityView.WalletTransfers,
        ActivityView.WalletDeposits,
        ActivityView.WalletWithdrawals
      ];
    });
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      const _component_CommonSeparator = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_unref(tabViewList)), (viewOption, index) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: `activity-tab-${viewOption}`
          }, [
            _createVNode(_component_AppSelectButton, {
              modelValue: _unref(view),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(view) ? view.value = $event : null),
              value: viewOption
            }, {
              default: _withCtx(({ active }) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["text-xs xs:text-sm leading-5 tracking-wide cursor-pointer uppercase", [
                    active ? "text-blue-500 hover:text-blue-600 font-bold" : "text-gray-500 hover:text-blue-500"
                  ]])
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, [
                      viewOption === _unref(ActivityView).Positions ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("activity.openPositions")), 1)) : viewOption === _unref(ActivityView).FundingPayments ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("activity.fundingPayments")), 1)) : [
                        _unref(ActivityView).DerivativeOrders,
                        _unref(ActivityView).SpotOrders
                      ].includes(viewOption) ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("activity.openOrders")), 1)) : [
                        _unref(ActivityView).DerivativeOrderHistory,
                        _unref(ActivityView).SpotOrderHistory
                      ].includes(viewOption) ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("activity.orderHistory")), 1)) : [
                        _unref(ActivityView).DerivativeTradeHistory,
                        _unref(ActivityView).SpotTradeHistory
                      ].includes(viewOption) ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("activity.tradeHistory")), 1)) : viewOption === _unref(ActivityView).DerivativeTriggers ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("activity.triggers")), 1)) : _createCommentVNode("", true),
                      viewOption === _unref(ActivityView).WalletTransfers ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("walletHistory.transfers.transfers")), 1)) : _createCommentVNode("", true),
                      viewOption === _unref(ActivityView).WalletDeposits ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("walletHistory.deposits")), 1)) : _createCommentVNode("", true),
                      viewOption === _unref(ActivityView).WalletWithdrawals ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("walletHistory.withdrawals")), 1)) : _createCommentVNode("", true)
                    ]),
                    viewOption === _unref(ActivityView).Positions ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, " (" + _toDisplayString(_unref(positionStore).subaccountPositionsCount) + ") ", 1)
                    ])) : _createCommentVNode("", true),
                    viewOption === _unref(ActivityView).SpotOrders ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, " (" + _toDisplayString(_unref(spotStore).subaccountOrdersCount) + ") ", 1)
                    ])) : _createCommentVNode("", true),
                    viewOption === _unref(ActivityView).DerivativeOrders ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                      _createElementVNode("span", _hoisted_18, " (" + _toDisplayString(_unref(derivativeStore).subaccountOrdersCount) + ") ", 1)
                    ])) : _createCommentVNode("", true),
                    viewOption === _unref(ActivityView).DerivativeTriggers ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, " (" + _toDisplayString(_unref(derivativeStore).subaccountConditionalOrdersCount) + ") ", 1)
                    ])) : _createCommentVNode("", true)
                  ])
                ], 2)
              ]),
              _: 2
            }, 1032, ["modelValue", "value"]),
            index !== Object.values(_unref(tabViewList)).length - 1 ? (_openBlock(), _createBlock(_component_CommonSeparator, { key: 0 })) : _createCommentVNode("", true)
          ], 64);
        }), 128))
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Activity/Common/Navigation.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Activity/Common/Tabs.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Activity/Common/ViewWrapper.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Activity/Common/Pagination.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/Card/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Modals/AddMargin.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "pt-6 h-full-flex" };
const _hoisted_2 = /* @__PURE__ */ _createElementVNode("div", { class: "mt-4 pt-4 pb-8 sm:pb-0 xs:mt-6 xs:pt-6 border-t" }, null, -1);
const _hoisted_3 = { class: "h-full rounded-xl overflow-y-auto" };
const _hoisted_4 = { class: "h-full-flex" };
import { Status, StatusType } from "@injectivelabs/utils";
import { UI_DEFAULT_PAGINATION_LIMIT_COUNT } from "@/app/utils/constants";
import { ActivityTab, ActivityView, ActivityField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const spotStore = useSpotStore();
    const bridgeStore = useBridgeStore();
    const activityStore = useActivityStore();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const { resetForm, setFieldValue } = useForm({
      keepValuesOnUnmount: true
    });
    const status = reactive(new Status(StatusType.Loading));
    const filterRef = ref();
    const paginationRef = ref();
    const tab = ref(ActivityTab.Positions);
    const view = ref(ActivityView.Positions);
    const action = computed(() => {
      switch (view.value) {
        case ActivityView.FundingPayments:
          return activityStore.fetchSubaccountFundingPayments;
        case ActivityView.SpotOrderHistory:
          return spotStore.fetchSubaccountOrderHistory;
        case ActivityView.SpotTradeHistory:
          return spotStore.fetchSubaccountTrades;
        case ActivityView.DerivativeOrderHistory:
          return derivativeStore.fetchSubaccountOrderHistory;
        case ActivityView.DerivativeTradeHistory:
          return derivativeStore.fetchSubaccountTrades;
        case ActivityView.WalletTransfers:
          return bridgeStore.fetchSubaccountTransfers;
        case ActivityView.WalletDeposits:
          return [
            bridgeStore.fetchPeggyDepositTransactions(),
            bridgeStore.fetchIBCTransferTransactions(),
            bridgeStore.fetchInjectiveTransactions()
          ];
        case ActivityView.WalletWithdrawals:
          return [
            bridgeStore.fetchPeggyWithdrawalTransactions(),
            bridgeStore.fetchIBCTransferTransactions(),
            bridgeStore.fetchInjectiveTransactions()
          ];
        default:
          return void 0;
      }
    });
    onMounted(() => {
      const promises = [
        activityStore.streamDerivativeSubaccountOrderHistory(),
        activityStore.streamDerivativeSubaccountTrades(),
        activityStore.streamSpotSubaccountOrderHistory(),
        activityStore.streamSpotSubaccountTrades(),
        derivativeStore.fetchSubaccountOrders(),
        derivativeStore.streamMarketsMarkPrices(),
        derivativeStore.fetchSubaccountConditionalOrders(),
        derivativeStore.streamSubaccountOrders(),
        positionStore.fetchSubaccountPositions(),
        positionStore.streamSubaccountPositions(),
        spotStore.fetchSubaccountOrders(),
        spotStore.streamSubaccountOrders()
      ];
      Promise.all(promises).then(() => {
        fetchData();
      }).catch($onError);
    });
    onUnmounted(() => {
      activityStore.$reset();
      derivativeStore.resetSubaccount();
      spotStore.resetSubaccount();
    });
    function fetchData() {
      var _a, _b;
      if (!action.value) {
        status.setIdle();
        return;
      }
      status.setLoading();
      Promise.all(
        Array.isArray(action.value) ? action.value : [
          action.value({
            filters: (_a = filterRef.value) == null ? void 0 : _a.filterParams,
            pagination: (_b = paginationRef.value) == null ? void 0 : _b.paginationOptions
          })
        ]
      ).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function onTabChange(tab2) {
      switch (tab2) {
        case ActivityTab.Positions:
          view.value = ActivityView.Positions;
          break;
        case ActivityTab.Derivatives:
          view.value = ActivityView.DerivativeOrders;
          break;
        case ActivityTab.Spot:
          view.value = ActivityView.SpotOrders;
          break;
        default:
          view.value = ActivityView.WalletTransfers;
          break;
      }
      onViewChange();
    }
    function handleFilterChange() {
      setFieldValue(ActivityField.Page, 1);
      setFieldValue(ActivityField.Limit, UI_DEFAULT_PAGINATION_LIMIT_COUNT);
      nextTick(() => {
        fetchData();
      });
    }
    function onViewChange() {
      resetForm();
      nextTick(() => {
        fetchData();
      });
    }
    return (_ctx, _cache) => {
      const _component_PartialsActivityCommonNavigation = __nuxt_component_0;
      const _component_PartialsActivityCommonTabs = __nuxt_component_1;
      const _component_PartialsActivityCommonViewWrapper = __nuxt_component_2;
      const _component_PartialsActivityCommonPagination = __nuxt_component_3;
      const _component_CommonCard = __nuxt_component_4;
      const _component_ModalsAddMargin = __nuxt_component_5;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_PartialsActivityCommonNavigation, {
            tab: _unref(tab),
            "onUpdate:tab": [
              _cache[0] || (_cache[0] = ($event) => _isRef(tab) ? tab.value = $event : null),
              onTabChange
            ],
            status: _unref(status)
          }, null, 8, ["tab", "status"]),
          _hoisted_2,
          _createVNode(_component_PartialsActivityCommonTabs, {
            view: _unref(view),
            "onUpdate:view": [
              _cache[1] || (_cache[1] = ($event) => _isRef(view) ? view.value = $event : null),
              onViewChange
            ],
            class: "pb-4 xs:pb-6",
            tab: _unref(tab)
          }, null, 8, ["view", "tab"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CommonCard, {
              md: "",
              class: "h-full-flex"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_PartialsActivityCommonViewWrapper, {
                    ref_key: "filterRef",
                    ref: filterRef,
                    tab: _unref(tab),
                    view: _unref(view),
                    status: _unref(status),
                    "onUpdate:filter": handleFilterChange,
                    "onReset:filter": onViewChange
                  }, null, 8, ["tab", "view", "status"]),
                  ![
                    _unref(ActivityView).Positions,
                    _unref(ActivityView).SpotOrders,
                    _unref(ActivityView).WalletDeposits,
                    _unref(ActivityView).DerivativeOrders,
                    _unref(ActivityView).WalletWithdrawals,
                    _unref(ActivityView).DerivativeTriggers
                  ].includes(_unref(view)) ? (_openBlock(), _createBlock(_component_PartialsActivityCommonPagination, {
                    key: 0,
                    ref_key: "paginationRef",
                    ref: paginationRef,
                    view: _unref(view),
                    status: _unref(status),
                    "onUpdate:filter": fetchData
                  }, null, 8, ["view", "status"])) : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_ModalsAddMargin)
      ], 64);
    };
  }
});

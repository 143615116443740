import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Pagination/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { Status, StatusType } from "@injectivelabs/utils";
import { UI_DEFAULT_PAGINATION_LIMIT_COUNT } from "@/app/utils/constants";
import { ActivityView, ActivityField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Pagination",
  props: {
    view: {
      type: String,
      required: true
    },
    status: {
      type: Object,
      default: () => new Status(StatusType.Idle)
    }
  },
  emits: ["update:filter"],
  setup(__props, { expose, emit }) {
    const props = __props;
    const activityStore = useActivityStore();
    const bridgeStore = useBridgeStore();
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const cachedEndTime = ref(void 0);
    const { value: page } = useNumberField({
      name: ActivityField.Page,
      initialValue: 1,
      rule: ""
    });
    const { value: limit, setValue: setLimitValue } = useNumberField({
      name: ActivityField.Limit,
      initialValue: UI_DEFAULT_PAGINATION_LIMIT_COUNT,
      rule: ""
    });
    const totalCount = computed(() => {
      switch (props.view) {
        case ActivityView.FundingPayments:
          return activityStore.subaccountFundingPaymentsCount;
        case ActivityView.SpotOrderHistory:
          return spotStore.subaccountOrderHistoryCount;
        case ActivityView.SpotTradeHistory:
          return spotStore.subaccountTradesCount;
        case ActivityView.DerivativeOrderHistory:
          return derivativeStore.subaccountOrderHistoryCount;
        case ActivityView.DerivativeTradeHistory:
          return derivativeStore.subaccountTradesCount;
        case ActivityView.WalletTransfers:
          return bridgeStore.subaccountTransferBridgeTransactionsCount;
        default:
          return 0;
      }
    });
    const endTime = computed(() => {
      var _a, _b, _c, _d, _e;
      switch (props.view) {
        case ActivityView.FundingPayments:
          return ((_a = activityStore.subaccountFundingPayments[0]) == null ? void 0 : _a.timestamp) || 0;
        case ActivityView.SpotOrderHistory:
          return ((_b = spotStore.subaccountOrderHistory[0]) == null ? void 0 : _b.updatedAt) || 0;
        case ActivityView.SpotTradeHistory:
          return ((_c = spotStore.subaccountTrades[0]) == null ? void 0 : _c.executedAt) || 0;
        case ActivityView.DerivativeOrderHistory:
          return ((_d = derivativeStore.subaccountOrderHistory[0]) == null ? void 0 : _d.updatedAt) || 0;
        case ActivityView.DerivativeTradeHistory:
          return ((_e = derivativeStore.subaccountTrades[0]) == null ? void 0 : _e.executedAt) || 0;
        default:
          return void 0;
      }
    });
    function handlePageChange(page2) {
      if (Number(page2) === 1) {
        cachedEndTime.value = void 0;
      } else if (!cachedEndTime.value) {
        cachedEndTime.value = endTime.value;
      }
      emit("update:filter");
    }
    function handleLimitChange(limit2) {
      setLimitValue(Number(limit2));
      emit("update:filter");
    }
    const paginationOptions = computed(() => {
      const skip = (page.value - 1) * limit.value;
      const isPageOne = skip === 0;
      return {
        skip,
        limit: limit.value,
        endTime: isPageOne ? void 0 : cachedEndTime.value
      };
    });
    watch(
      () => props.view,
      () => cachedEndTime.value = void 0
    );
    expose({
      paginationOptions
    });
    return (_ctx, _cache) => {
      const _component_AppPagination = __nuxt_component_0;
      return _unref(totalCount) > 0 ? (_openBlock(), _createBlock(_component_AppPagination, {
        key: 0,
        page: _unref(page),
        "onUpdate:page": [
          _cache[0] || (_cache[0] = ($event) => _isRef(page) ? page.value = $event : null),
          handlePageChange
        ],
        limit: _unref(limit),
        "onUpdate:limit": [
          _cache[1] || (_cache[1] = ($event) => _isRef(limit) ? limit.value = $event : null),
          handleLimitChange
        ],
        class: "flex-none mt-4 h-9",
        "row-class": "border border-transparent",
        disabled: __props.status.isLoading(),
        "total-count": _unref(totalCount)
      }, {
        "rows-prefix": _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("pagination.showRows")) + ":", 1)
        ]),
        _: 1
      }, 8, ["page", "limit", "disabled", "total-count"])) : _createCommentVNode("", true);
    };
  }
});

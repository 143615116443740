"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Select",
  props: {
    lg: Boolean,
    active: Boolean,
    showLoading: Boolean
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_AppSpinner = _resolveComponent("AppSpinner");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["group w-full border-t-4 cursor-pointer shadow-card text-gray-100 bg-gray-900", {
          "border-blue-500 rounded-b-md": __props.active,
          "border-transparent rounded-b-md opacity-50 rounded-t-md hover:border-blue-500 hover:rounded-b-md hover:rounded-t-none hover:opacity-100": !__props.active,
          "px-4 py-5": __props.lg,
          "p-3 lg:px-4 lg:py-5": !__props.lg
        }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["h-full flex flex-col", {
            "min-w-2xs sm:min-w-full": __props.lg
          }])
        }, [
          _renderSlot(_ctx.$slots, "subtitle"),
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center h-full gap-4", {
              "justify-between": __props.lg
            }])
          }, [
            __props.showLoading ? (_openBlock(), _createBlock(_component_AppSpinner, { key: 0 })) : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["rounded-full mr-4 flex items-center justify-center", [
                __props.lg ? "min-w-12 w-12 h-12" : "w-4 h-4 md:w-6 md:h-6",
                {
                  "bg-blue-500 text-blue-900": __props.active,
                  "bg-gray-600 group-hover:bg-blue-850": !__props.active
                }
              ]])
            }, [
              _renderSlot(_ctx.$slots, "icon")
            ], 2)),
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ], 2)
      ], 2);
    };
  }
});
